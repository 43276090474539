import actions from "../actions/actions";

const loaderMiddleware = (api) => (next) => (action) => {
  const response = next(action);
  if (
    action.type.includes("ASYNC") &&
    !action.type.includes("SUCCESS") &&
    !action.type.includes("FAILED")
  ) {
    api.dispatch(actions.showLoader(true));
  }

  if (
    action.type.includes("ASYNC") &&
    (action.type.includes("SUCCESS") || action.type.includes("FAILED"))
  ) {
    api.dispatch(actions.showLoader(false));
  }

  return response;
};

export default loaderMiddleware;
