export const ASYNC_AUTH = "ASYNC_AUTH";
export const ASYNC_AUTH_SUCCESS = "ASYNC_AUTH_SUCCESS";
export const ASYNC_AUTH_FAILED = "ASYNC_AUTH_FAILED";

export const ASYNC_GET_TOKEN = "ASYNC_GET_TOKEN";
export const ASYNC_GET_TOKEN_SUCCESS = "ASYNC_GET_TOKEN_SUCCESS";
export const ASYNC_GET_TOKEN_FAILED = "ASYNC_GET_TOKEN_FAILED";

export const ASYNC_LOGOUT = "ASYNC_LOGOUT";
export const ASYNC_LOGOUT_SUCCESS = "ASYNC_LOGOUT_SUCCESS";
export const ASYNC_LOGOUT_FAILED = "ASYNC_LOGOUT_FAILED";

export const doAuth = (payload) => {
  return {
    type: ASYNC_AUTH,
    payload,
  };
};

export const getToken = () => {
  return {
    type: ASYNC_GET_TOKEN,
  };
};

export const logout = () => {
  return {
    type: ASYNC_LOGOUT,
  };
};
