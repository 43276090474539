export const NOTIFY_SUCCESS_MESSAGE = "NOTIFY_SUCCESS_MESSAGE";
export const NOTIFY_WARNING_MESSAGE = "NOTIFY_WARNING_MESSAGE";
export const NOTIFY_ERROR_MESSAGE = "NOTIFY_ERROR_MESSAGE";

const NOTIFICATION_TYPE = {
  SUCCESS: "success",
  WARNING: "warning",
  ERROR: "error",
};

export const notifySuccess = (payload) => {
  return {
    type: NOTIFY_SUCCESS_MESSAGE,
    payload: {
      msg: payload,
      type: NOTIFICATION_TYPE.SUCCESS,
    },
  };
};

export const notifyWarning = (payload) => {
  return {
    type: NOTIFY_WARNING_MESSAGE,
    payload: {
      msg: payload,
      type: NOTIFICATION_TYPE.WARNING,
    },
  };
};

export const notifyError = (payload) => {
  return {
    type: NOTIFY_ERROR_MESSAGE,
    payload: {
      msg: payload,
      type: NOTIFICATION_TYPE.ERROR,
    },
  };
};
