import { all } from "redux-saga/effects";

import {
  listenGetFailedPayments,
  listenGetAllPayments,
  listenPostPayment,
} from "./payment.sagas";
import { listenDoAuth, listenGetToken } from "./auth.sagas";
import { listenGetDebtorActivity } from "./activity.sagas";

export default function* rootSaga() {
  yield all([
    listenGetFailedPayments(),
    listenGetAllPayments(),
    listenPostPayment(),
    listenGetDebtorActivity(),
    listenDoAuth(),
    listenGetToken(),
  ]);
}
