import { Route, Routes } from "react-router-dom";
import RouteConfig from "./config";
import FailedPayments from "../pages/Payments/FailedPayments";
import Payments from "../pages/Payments/Payments";
import Activity from "../pages/Activity/Activity";

const PrivateRoutes = () => {
  return (
    <>
      <Routes>
        <Route
          path={RouteConfig.PRIVATE.FAILED_PAYMENTS.URL}
          element={<FailedPayments />}
        />
        <Route path={RouteConfig.PRIVATE.PAYMENTS.URL} element={<Payments />} />
        <Route path={RouteConfig.PRIVATE.ACTIVITY.URL} element={<Activity />} />
      </Routes>
    </>
  );
};

export default PrivateRoutes;
