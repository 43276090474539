import actions from "../actions/actions";

const initialState = {
  message: false,
  type: false,
};

export const notificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.NOTIFY_SUCCESS_MESSAGE: {
      return {
        ...state,
        type: action.payload.type,
        message: action.payload.msg,
      };
    }

    case actions.NOTIFY_WARNING_MESSAGE: {
      return {
        ...state,
        type: action.payload.type,
        message: action.payload.msg,
      };
    }
    case actions.NOTIFY_ERROR_MESSAGE: {
      return {
        ...state,
        type: action.payload.type,
        message: action.payload.msg,
      };
    }

    default: {
      return state;
    }
  }
};
