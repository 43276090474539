import React, { useEffect, useState } from "react";

import BaseLayout from "../components/layout/BaseLayout";
import PrivateRoutes from "./privateRoutes";
import PublicRoutes from "./publicRoutes";
// import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import actions from "../store/actions/actions";
// import { Loader } from "../common/Loader";
import { useNavigate, useLocation } from "react-router-dom";
import RouteConfig from "./config";

const Routes = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const isLoading = useSelector((state) => state?.loader?.show);
  const [pathName, setPathName] = useState();

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const authState = useSelector((state) => state?.authn);

  // get token on mount
  useEffect(() => {
    dispatch(actions.getToken());
    return () => {
      setPathName(undefined);
    };
  }, []);

  useEffect(() => {
    if (!authState?.isLoggedIn) setPathName(location.pathname);
  }, [location]);

  useEffect(() => {
    // if logged in set state
    // redirect to last path
    if (authState?.isLoggedIn) {
      setIsAuthenticated(true);
      if (!pathName?.includes(RouteConfig.PUBLIC.LOGIN)) navigate(pathName);
    }

    // if not logged  in
    // set state & redirect to login
    if (
      !authState?.isLoggedIn &&
      !location.pathname.includes(RouteConfig.PUBLIC.LOGIN)
    ) {
      setIsAuthenticated(false);
      setPathName(location.pathname);
      navigate(RouteConfig.PUBLIC.LOGIN);
    }

    if (!authState?.isLoggedIn) {
      setPathName(location.pathname);
    }
  }, [authState?.isLoggedIn]);

  const getRoutes = () => {
    return isAuthenticated ? (
      <>
        <BaseLayout>
          <PrivateRoutes />
        </BaseLayout>
      </>
    ) : (
      <>
        {/* {isLoading ? <Loader /> : ""} */}
        <div className={isLoading ? "d-none" : ""}>
          <PublicRoutes />
        </div>
      </>
    );
  };

  return getRoutes();
};

export default Routes;
