import { Navigate, Route, Routes } from "react-router-dom";
import Login from "../pages/Login/Login";
// import SecurityCheck from "../pages/Login/SecurityCheck";
import RouteConfig from "./config";

const PublicRoutes = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<Navigate to={RouteConfig.PUBLIC.LOGIN} />} />
        <Route path={RouteConfig.PUBLIC.LOGIN} element={<Login />} />
        {/* <Route path={RouteConfig.PUBLIC.SECURITY} element={<SecurityCheck />} /> */}
        {/* <Route
          path="*"
          element={<Navigate to={RouteConfig.PUBLIC.LOGIN} replace={true} />}
        ></Route> */}
      </Routes>
    </>
  );
};

export default PublicRoutes;
