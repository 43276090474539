import * as paymentActions from "./payment.actions";
import * as authActions from "./auth.actions";
import * as notificationActions from "./notification.actions";
import * as loaderActions from "./loader.actions";
import * as activityActions from "./activity.actions";

const actions = {
  ...paymentActions,
  ...authActions,
  ...notificationActions,
  ...loaderActions,
  ...activityActions,
};

export default actions;
