import { useIdleTimer } from "react-idle-timer";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import actions from "../store/actions/actions";

const IDLE_TIMEOUT = 20 * 60 * 1000;

export const IdleTimer = () => {
  const [isLoggedOut, setIsLoggedOut] = useState(false);

  const dispatch = useDispatch();

  const doLogout = () => {
    setIsLoggedOut(true);
  };

  useIdleTimer({
    timeout: IDLE_TIMEOUT, // time in millisecond
    onIdle: () => doLogout(),
  });

  useEffect(() => {
    // console.log("isLoggedOut", isLoggedOut);
    if (isLoggedOut) dispatch(actions.logout());
  }, [isLoggedOut]);
};
