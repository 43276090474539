import { StorageService } from "../storage";
import instance from "./instance";

class AuthnService {
  storageService;
  constructor() {
    this.storageService = new StorageService();
  }

  auth = async (payload) => {
    try {
      return await instance.post(`/auth/login`, payload);
    } catch (error) {
      console.log("[auth error]", error);
      throw error;
    }
  };

  setLogoutTimer = (timer) => {
    localStorage.setItem(StorageService.STORAGE_KEYS.TIMER, timer);
  };

  getLogoutTimer = () => {
    return localStorage.getItem(StorageService.STORAGE_KEYS.TIMER);
  };

  setTimeShow = (show) => {
    return localStorage.setItem(StorageService.STORAGE_KEYS.TIMER_SHOW, show);
  };

  getTimerShow = () => {
    return localStorage.getItem(StorageService.STORAGE_KEYS.TIMER_SHOW);
  };

  getToken = async () => {
    const storageService = new StorageService();
    return storageService.getItem(StorageService.STORAGE_KEYS.CUSTOMER_TOKEN);
  };
}

export default AuthnService;
