import actions from "../actions/actions";

const initialState = {
  login: false,
  isLoggedIn: false,
};

export const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.ASYNC_AUTH_SUCCESS: {
      return {
        ...state,
        login: true,
        isLoggedIn: true,
      };
    }

    case actions.ASYNC_GET_TOKEN_SUCCESS: {
      return {
        ...state,
        isLoggedIn: true,
      };
    }

    case actions.ASYNC_GET_TOKEN_FAILED: {
      return {
        ...state,
      };
    }

    case "UNAUTHORIZED": {
      return {
        ...state,
        isLoggedIn: false,
        token: false,
      };
    }

    default: {
      return state;
    }
  }
};
