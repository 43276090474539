import { combineReducers } from "@reduxjs/toolkit";
import { paymentReducer } from "./payment.reducer";
import { authReducer } from "./auth.reducer";
import { notificationReducer } from "./notification.reducer";
import { loaderReducer } from "./loader.reducer";
import { activityReducer } from "./activity.reducer";

export const rootReducer = combineReducers({
  payment: paymentReducer,
  authn: authReducer,
  notification: notificationReducer,
  loader: loaderReducer,
  activity: activityReducer,
});
