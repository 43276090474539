import {
  PoundCircleOutlined,
  UsergroupDeleteOutlined,
} from "@ant-design/icons";

const RouteConfig = {
  PUBLIC: {
    LOGIN: "/login",
    SET_PASSOWRD: "/set",
  },
  PRIVATE: {
    FAILED_PAYMENTS: {
      TITLE: "Failed Payments",
      URL: "/failed-payments",
      ICON: <PoundCircleOutlined />,
    },
    PAYMENTS: {
      TITLE: "All Payments",
      URL: "/payments",
      ICON: <PoundCircleOutlined />,
    },
    ACTIVITY: {
      TITLE: "Debtor Activity",
      URL: "/activity",
      ICON: <UsergroupDeleteOutlined />,
    },
  },
};

export default RouteConfig;
