export const ASYNC_GET_FAILURE_PAYMENTS = "ASYNC_GET_FAILURE_PAYMENTS";
export const ASYNC_GET_FAILURE_PAYMENTS_SUCCESS =
  "ASYNC_GET_FAILURE_PAYMENTS_SUCCESS";
export const ASYNC_GET_FAILURE_PAYMENTS_FAILED =
  "ASYNC_GET_FAILURE_PAYMENTS_FAILED";

export const ASYNC_GET_ALL_PAYMENTS = "ASYNC_GET_ALL_PAYMENTS";
export const ASYNC_GET_ALL_PAYMENTS_SUCCESS = "ASYNC_GET_ALL_PAYMENTS_SUCCESS";
export const ASYNC_GET_ALL_PAYMENTS_FAILED = "ASYNC_GET_ALL_PAYMENTS_FAILED";

export const ASYNC_POST_PUSH_PAYMENT = "ASYNC_POST_PUSH_PAYMENT";
export const ASYNC_POST_PUSH_PAYMENT_SUCCESS =
  "ASYNC_POST_PUSH_PAYMENT_SUCCESS";
export const ASYNC_POST_PUSH_PAYMENT_FAILED = "ASYNC_POST_PUSH_PAYMENT_FAILED";

export const RESET_PAYMENT_STATE = "RESET_PAYMENT_STATE";

export const getFailedPayments = (payload) => {
  return {
    type: ASYNC_GET_FAILURE_PAYMENTS,
    payload,
  };
};

export const getAllPayments = (payload) => {
  return {
    type: ASYNC_GET_ALL_PAYMENTS,
    payload,
  };
};

export const pushPayment = (payload) => {
  return {
    type: ASYNC_POST_PUSH_PAYMENT,
    payload,
  };
};

export const resetPaymentState = () => {
  return {
    type: RESET_PAYMENT_STATE,
  };
};
