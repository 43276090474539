import axios from "axios";
import { generateResponseInterceptor } from "../interceptor";

const instance = axios.create({
  baseURL: process.env.REACT_APP_DEBTOR_API_URL,
  withCredentials: true,
});

generateResponseInterceptor(instance);
export default instance;
