import React, { useEffect, useState } from "react";
import { Table } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getDebtroActivity } from "../../store/actions/activity.actions";
import moment from "moment";
import Search from "antd/es/input/Search";
import { render } from "@testing-library/react";

const SORT_KEY = {
  created_at: "created_at",
};

const AUTH_STATUS = {
  CASE_AUTH: "Security Check",
  CASE_VALIDATE: "Case Validation",
};

export default () => {
  const [data, setData] = useState([]);
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
    searchKey: "",
  });

  const activityData = useSelector((state) => state.activity.activityData);
  const dispatch = useDispatch();

  useEffect(() => {
    if (activityData && activityData?.records?.length !== 0) {
      setData(activityData.records);
      setTableParams({
        ...tableParams,
        pagination: {
          ...tableParams.pagination,
          total: activityData.totalCount,
        },
      });
      // setTotalRecordsCount(activityData.totalCount);
    }
  }, [activityData]);

  useEffect(() => {
    dispatch(getDebtroActivity(tableParams));
  }, [
    tableParams.pagination?.current,
    tableParams?.sortOrder,
    tableParams?.sortField,
    JSON.stringify(tableParams.filters),
    tableParams?.searchKey,
  ]);

  const columns = [
    {
      title: "Case Id",
      dataIndex: "input",
      key: "case_id",
      render: (input) => input.split("|")[0],
    },
    {
      title: "Case Security Input",
      dataIndex: "input",
      key: "case_security_input",
      render: (input) => input?.split("|")?.[1],
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (action) => <span>{AUTH_STATUS[action]}</span>,
    },
    {
      title: "Is Success",
      dataIndex: "is_success",
      key: "is_success",
      render: (isSuccess) => <span>{isSuccess === 1 ? "Yes" : "No"}</span>,
    },
    {
      title: "User Agent",
      dataIndex: "user_agent",
      key: "user_agent",
    },
    {
      title: "IP Address",
      dataIndex: "ip_address",
      key: "ip_address",
    },
    {
      title: "Created at",
      dataIndex: "created_at",
      key: "created_at",
      render: (createdAt) => {
        return moment(createdAt).toString();
      },
      sorter: true,      
    },
  ];

  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams({
      pagination,
      filters,
      sortOrder: Array.isArray(sorter) ? undefined : sorter.order,
      sortField: Array.isArray(sorter) ? undefined : SORT_KEY[sorter.field],
    });

    // `dataSource` is useless since `pageSize` changed
    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setData([]);
    }
  };

  const handleOnSearch = (key) => {
    if (key && key?.length)
      setTableParams({
        ...tableParams,
        searchKey: key,
      });
  };

  return (
    <Table
      size="middle"
      columns={columns}
      dataSource={data}
      pagination={{
        pageSize: tableParams.pagination.pageSize,
        total: tableParams.pagination?.total,
      }}
      onChange={handleTableChange}
      title={() => (
        <>
          {" "}
          <Search
            placeholder="input search text"
            onSearch={handleOnSearch}
            enterButton
          />
        </>
      )}
    />
  );
};
