import ActivityService from "../../services/activity";
import actions from "../actions/actions";
import { put, call, takeLatest } from "redux-saga/effects";

const activityService = new ActivityService();

const getDebtroActivity = async (payload) => {
  try {
    return await activityService.getDebtroActivity(payload);
  } catch (error) {
    throw error;
  }
};

function* dispatchGetDebtorActivity(action) {
  try {
    const response = yield call(getDebtroActivity, action?.payload);
    if (response.data.success) {
      yield put({
        type: actions.ASYNC_GET_DEBTOR_ACTIVITY_SUCCESS,
        data: response.data.data,
        msg: response.data.msg,
      });
    } else {
      yield put({
        type: actions.ASYNC_GET_DEBTOR_ACTIVITY_FAILED,
        msg: response.data.msg,
      });
    }
  } catch (error) {
    yield put({
      type: actions.ASYNC_GET_DEBTOR_ACTIVITY_FAILED,
      msg: error.response?.data?.msg,
    });
  }
}

export function* listenGetDebtorActivity() {
  yield takeLatest(
    actions.ASYNC_GET_DEBTOR_ACTIVITY,
    dispatchGetDebtorActivity
  );
}
