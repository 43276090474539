import { Anchor } from "antd";
import React from "react";
import { ReactComponent as Logo } from "../../common/icons/logo.svg";
import { ReactComponent as ChevronDown } from "../../common/icons/chevron-down.svg";

import { Dropdown, Space } from "antd";
// import { DownOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import actions from "../../store/actions/actions";
import RouteConfig from "../../routes/config";
// import { useTranslation } from "react-i18next";

const NavBar = () => {
  const dispatch = useDispatch();
  // const { t } = useTranslation();

  const languages = [
    {
      key: "1",
      label: <span>English</span>,
    },
    // {
    //   key: "2",
    //   label: <span>French</span>,
    // },
  ];

  const hanldeOnLogout = () => {
    dispatch(actions.logout());
    // dispatch(actions.notifySuccess(t("Login.Notification - Signout")));
  };

  return (
    <div>
      <Anchor className="nav-bar" direction="horizontal">
        <Logo className="logo" />
        {/* <span className="logout-btn" onClick={hanldeOnLogout}>
          <h2>Logout</h2>
        </span> */}
      </Anchor>
    </div>
  );
};

export default NavBar;
