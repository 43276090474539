import { useEffect, useState } from "react";
import AuthService from "../services/auth";
// import moment from "moment";
import { useDispatch } from "react-redux";
import actions from "../store/actions/actions";
import { Button, Modal } from "antd";

const LOGOUT_TIMEOUT = 3600;
const promptBeforeIdle = 60;

export const Timer = () => {
  const authService = new AuthService();
  const localTimer = authService.getLogoutTimer();
  const timeShow = authService.getTimerShow();
  // console.log("[localTimer]", localTimer);

  const [duration, setDuration] = useState(
    localTimer > 0 ? localTimer : LOGOUT_TIMEOUT
  );
  const [paused, setPaused] = useState(false);
  const [open, setOpen] = useState(false);
  const [hasPopupShown, setHasPopupShown] = useState(timeShow ? timeShow : 0);
  const [isLoggedOut, setIsLoggedOut] = useState(false);

  const dispatch = useDispatch();

  const doLogout = () => {
    setIsLoggedOut(true);
  };

  useEffect(() => {
    let timerId;
    if (!paused) {
      timerId = setInterval(() => {
        setDuration((prev) => {
          // console.log("prev", prev);
          authService.setLogoutTimer(prev - 1);
          return prev - 1;
        });
      }, 1000);
      // console.log(timerId);
    }

    return function cleanup() {
      // console.log(`Clearing ${timerId}`);
      clearInterval(timerId);
    };
  }, [paused, localTimer]);

  useEffect(() => {
    // console.log("isLoggedOut", isLoggedOut);
    // if (isLoggedOut) dispatch(actions.logout());
  }, [isLoggedOut]);

  useEffect(() => {
    // console.log("duration", duration, LOGOUT_TIMEOUT, promptBeforeIdle, open);
    if (duration <= 0) doLogout();

    if (promptBeforeIdle >= duration) return onPrompt();
  }, [duration]);

  useEffect(() => {
    // console.log("isLoggedOut", isLoggedOut);
    // if (isLoggedOut) dispatch(actions.logout());
  }, [isLoggedOut]);

  //   const handleClick = (e) => {
  //     !paused ? setPaused(true) : setPaused(false);
  //   };

  const handleOk = () => {
    setOpen(false);
    doLogout();
  };
  const handleCancel = () => {
    setOpen(false);
  };

  const onPrompt = () => {
    if (hasPopupShown !== "1" && !hasPopupShown) {
      setOpen(true);
      authService.setTimeShow("1");
      setHasPopupShown(true);
    }
  };

  return (
    <>
      {/* {duration >= 0 ? (
        <h3>{moment().startOf("day").seconds(duration).format("H:mm:ss")}</h3>
      ) : (
        setDuration(0)
      )} */}

      <Modal
        open={open}
        title="Title"
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button type="primary" onClick={handleOk} className="modal-timer-btn">
            Continue
          </Button>,
        ]}
      >
        "You will be logged out after 1 minute"
      </Modal>
    </>
  );
};
