import store from "../store/store";

export const generateResponseInterceptor = (instance) => {
  return instance.interceptors.response.use(
    (response) => {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      return response;
    },
    (error) => {
      console.log(error)
      if (error?.response?.status === 401 || error?.response?.status === 403) {
        store.dispatch({
          type: "UNAUTHORIZED",
        });
      }
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      return Promise.reject(error);
    }
  );
};
