import actions from "../actions/actions";

const initialState = {
  show: false,
};

export const loaderReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.SHOW_LOADER: {
      return {
        ...state,
        show: action.payload,
      };
    }

    case actions.STOP_PAYMENT_STATUS_POLLING: {
      return {
        ...state,
        show: false,
      };
    }

    default: {
      return state;
    }
  }
};
