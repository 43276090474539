import "./styles/App.scss";
import { BrowserRouter } from "react-router-dom";
import Routes from "./routes";
import { message } from "antd";
import { useEffect } from "react";
import { useSelector } from "react-redux";

function App() {
  const [messageApi, contextHolder] = message.useMessage();
  const notificationState = useSelector((state) => state.notification);

  useEffect(() => {
    if (notificationState.message && notificationState.type)
      messageApi.open({
        type: notificationState.type,
        content: notificationState.message,
      });
  }, [notificationState]);

  return (
    <div className="app">
      {contextHolder}
      <BrowserRouter>
        <Routes />
      </BrowserRouter>
    </div>
  );
}

export default App;
