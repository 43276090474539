import instance from "./instance";

const getSortOrder = (sortOrder) => {
  if (!sortOrder) return "DESC";

  return sortOrder.includes("descend") ? "DESC" : "ASC";
};

class PaymentService {
  getFailedPayments = async (payload) => {
    try {
      console.log({ payload });
      return await instance.get(`/payments/failed`, {
        params: {
          page: payload?.pagination?.current,
          sortBy: payload?.sortField,
          sort: getSortOrder(payload?.sortOrder),
          search: payload?.searchKey,
        },
      });
    } catch (error) {
      console.log("[getFailedPayments error]", error);
      throw error;
    }
  };

  getAllPayments = async (payload) => {
    try {
      console.log({ payload });
      return await instance.get(`/payments`, {
        params: {
          page: payload?.pagination?.current,
          sortBy: payload?.sortField,
          sort: getSortOrder(payload?.sortOrder),
          search: payload?.searchKey,
        },
      });
    } catch (error) {
      console.log("[getAllPayments error]", error);
      throw error;
    }
  };

  postPayment = async (id) => {
    try {
      return await instance.post("/payments/push", {
        id,
      });
    } catch (error) {
      console.log("[postPayment error]", error);

      throw error;
    }
  };
}

export default PaymentService;
