import { configureStore } from "@reduxjs/toolkit";
import { rootReducer } from "./reducers/reducers";
import createSagaMiddleware from "redux-saga";
import rootSaga from "./sagas/sagas";
import loaderMiddleware from "./middleware/loadig.middleware";

const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(sagaMiddleware).concat(loaderMiddleware),
});

sagaMiddleware.run(rootSaga);

export default store;
