import React, { useEffect, useState } from "react";
import {
  Col,
  Row,
  Typography,
  Form,
  Input,
  Button,
  Popover,
  Carousel,
} from "antd";
import { useNavigate } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import actions from "../../store/actions/actions";
// import { ReactComponent as Logo } from "../../common/icons/logo.svg";
import { QuestionCircleOutlined } from "@ant-design/icons";
import ConfirmModal from "../../common/ConfirmModal";
import RouteConfig from "../../routes/config";

const { Title, Text } = Typography;

const LoginForm = () => {
  const [showModal, setShowModal] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const authState = useSelector((state) => state.authn);

  useEffect(() => {
    if (authState?.isLoggedIn) {
      // dispatch(actions.removeValidatedCase());
      navigate(RouteConfig.PRIVATE.FAILED_PAYMENTS.URL);
    }
  }, [authState?.login, navigate]);

  const handleOnFormFinish = (values) => {
    dispatch(
      actions.doAuth({
        username: values.email,
        password: values.password,
      })
    );
  };

  return (
    <Row className="login-form" justify="center">
      {/* <Logo className="logo" /> */}
      <Col className="login-page--header">
        <Title level={1}>Newlyn Admin Portal</Title>

        <Form
          layout="vertical"
          className="mt-2"
          onFinish={handleOnFormFinish}
          autoComplete="off"
        >
          <Form.Item name="email" label="Email" rules={[{ required: true }]}>
            <Input className="input-case-id" autoComplete="off" />
          </Form.Item>

          <Form.Item
            name="password"
            label="Password"
            rules={[{ required: true }]}
          >
            <Input
              type="password"
              className="input-case-id"
              autoComplete="off"
            />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" className="btn-continue">
              Proceed
            </Button>
          </Form.Item>
        </Form>
      </Col>
    </Row>
  );
};

export default LoginForm;
