import actions from "../actions/actions";

const initialState = {
  payments: false,
};

export const paymentReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.ASYNC_GET_FAILURE_PAYMENTS_SUCCESS: {
      return {
        ...state,
        payments: action.data,
      };
    }

    case actions.ASYNC_GET_ALL_PAYMENTS_SUCCESS: {
      return {
        ...state,
        payments: action.data,
      };
    }

    case actions.RESET_PAYMENT_STATE: {
      return initialState;
    }

    default: {
      return state;
    }
  }
};
