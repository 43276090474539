const formatter = new Intl.NumberFormat("en-UK", {
  style: "currency",
  currency: "GBP",
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

/**
 * @description - Fromats the amount
 * @param amount - amount to be formnatted
 * @returns - formatted amount
 */
export const format = (amount) => {
  return formatter.format(amount).replace("£", "");
};
