import PaymentService from "../../services/payment";
import actions from "../actions/actions";
import { put, call, takeLatest } from "redux-saga/effects";

const paymentService = new PaymentService();

const getFailedPayments = async (payload) => {
  try {
    return await paymentService.getFailedPayments(payload);
  } catch (error) {
    throw error;
  }
};

const getAllPayments = async (payload) => {
  try {
    return await paymentService.getAllPayments(payload);
  } catch (error) {
    throw error;
  }
};

const postPayment = async (payload) => {
  try {
    return await paymentService.postPayment(payload);
  } catch (error) {
    throw error;
  }
};

function* dispatchGetFailedPayments(action) {
  try {
    const response = yield call(getFailedPayments, action?.payload);
    if (response.data.success) {
      yield put({
        type: actions.ASYNC_GET_FAILURE_PAYMENTS_SUCCESS,
        data: response.data.data,
        msg: response.data.msg,
      });
    } else {
      yield put({
        type: actions.ASYNC_GET_FAILURE_PAYMENTS_FAILED,
        msg: response.data.msg,
      });
    }
  } catch (error) {
    yield put({
      type: actions.ASYNC_GET_FAILURE_PAYMENTS_FAILED,
      msg: error.response?.data?.msg,
    });
  }
}

function* dispatchGetAllPayments(action) {
  try {
    const response = yield call(getAllPayments, action?.payload);
    if (response.data.success) {
      yield put({
        type: actions.ASYNC_GET_ALL_PAYMENTS_SUCCESS,
        data: response.data.data,
        msg: response.data.msg,
      });
    } else {
      yield put({
        type: actions.ASYNC_GET_ALL_PAYMENTS_FAILED,
        msg: response.data.msg,
      });
    }
  } catch (error) {
    yield put({
      type: actions.ASYNC_GET_ALL_PAYMENTS_FAILED,
      msg: error.response?.data?.msg,
    });
  }
}

function* dispatchPostPayment(action) {
  try {
    const response = yield call(postPayment, action?.payload);
    if (response.data.success) {
      yield put({
        type: actions.ASYNC_POST_PUSH_PAYMENT_SUCCESS,
        data: response.data.data,
        msg: response.data.msg,
      });
    } else {
      yield put({
        type: actions.ASYNC_POST_PUSH_PAYMENT_FAILED,
        msg: response.data.msg,
      });
    }
  } catch (error) {
    yield put({
      type: actions.ASYNC_POST_PUSH_PAYMENT_FAILED,
      msg: error.response?.data?.msg,
    });
  }
}

export function* listenGetFailedPayments() {
  yield takeLatest(
    actions.ASYNC_GET_FAILURE_PAYMENTS,
    dispatchGetFailedPayments
  );
}

export function* listenGetAllPayments() {
  yield takeLatest(actions.ASYNC_GET_ALL_PAYMENTS, dispatchGetAllPayments);
}

export function* listenPostPayment() {
  yield takeLatest(actions.ASYNC_POST_PUSH_PAYMENT, dispatchPostPayment);
}
