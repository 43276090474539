import React from "react";
import { Col, Row } from "antd";
import LoginForm from "./LoginForm";

export default () => {
  return (
    <Row className="login">
      <Col className="login-page">
        <LoginForm />
      </Col>
    </Row>
  );
};
