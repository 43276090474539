import React, { useEffect, useState } from "react";
import { Button, Table, Skeleton } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getFailedPayments } from "../../store/actions/payment.actions";
import moment from "moment";
import Search from "antd/es/input/Search";
import actions from "../../store/actions/actions";
import { format } from "../../util/CurrencyUtil";

const SORT_KEY = {
  case: "case_id",
  transaction_id: "transaction_id",
  created_at: "created_at",
};

export default () => {
  const [data, setData] = useState([]);
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
    searchKey: "",
  });
  const [idToPush, setIdToPush] = useState(false);

  const paymentData = useSelector((state) => state.payment.payments);
  const dispatch = useDispatch();

  useEffect(() => {
    if (paymentData && paymentData?.records?.length !== 0) {
      setData(paymentData.records);
      setTableParams({
        ...tableParams,
        pagination: {
          ...tableParams.pagination,
          total: paymentData.totalCount,
        },
      });
      // setTotalRecordsCount(paymentData.totalCount);
    }
  }, [paymentData]);

  useEffect(() => {
    dispatch(getFailedPayments(tableParams));
  }, [
    tableParams.pagination?.current,
    tableParams?.sortOrder,
    tableParams?.sortField,
    JSON.stringify(tableParams.filters),
    tableParams?.searchKey,
  ]);

  useEffect(() => {
    if (idToPush) {
      dispatch(actions.pushPayment(idToPush));
    }
  }, [idToPush]);

  const columns = [
    {
      title: "Case Id",
      dataIndex: "case",
      key: "case_id",
      render: (caseRow) => caseRow.case_id,
      sorter: true,
    },
    {
      title: "Payment Reference",
      dataIndex: "transaction_id",
      key: "payment_reference",
      render: (text) => {
        const data = JSON.parse(text);
        return data?.reference;
      },
      sorter: true,
    },
    {
      title: "Checkout Payment ID",
      dataIndex: "transaction_id",
      key: "c_payment_reference",
      render: (text) => {
        const data = JSON.parse(text);
        return data?.payment_id;
      },
      // sorter: true,
    },
    {
      title: "Amount",
      dataIndex: "payment_received",
      key: "payment_received",
      render: (amount) => `£ ${format(parseFloat(amount).toFixed(2))}`,
    },
    {
      title: "Created at",
      dataIndex: "created_at",
      key: "created_at",
      render: (createdAt) => {
        return moment(createdAt).toString();
      },
      sorter: true,
      defaultSortOrder: "descend",
    },
    {
      title: "Actions",
      dataIndex: "id",
      key: "action",
      //   onCell: (_, index) => ({
      //     colSpan: index === 3 ? 2 : 1,
      //   }),
      render: (id) => (
        <>
          <Button
            key={`btn-action-${id}`}
            data={id}
            type="primary"
            onClick={(_) => handleOnPushPayment(id)}
          >
            {" "}
            Push Payment
          </Button>
          {/* <Button
            key={`btn-action-${id}`}
            data={id}
            type="primary"
            style={{ marginLeft: "1rem" }}
          >
            {" "}
            Mark as Success
          </Button> */}
        </>
      ),
    },
  ];

  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams({
      pagination,
      filters,
      sortOrder: Array.isArray(sorter) ? undefined : sorter.order,
      sortField: Array.isArray(sorter) ? undefined : SORT_KEY[sorter.field],
    });

    // `dataSource` is useless since `pageSize` changed
    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setData([]);
    }
  };

  const handleOnSearch = (key) => {
    if (key && key?.length)
      setTableParams({
        ...tableParams,
        searchKey: key,
      });
  };

  const handleOnPushPayment = (id) => {
    setIdToPush(id);
  };

  return (
    <Table
      size="middle"
      columns={columns}
      dataSource={data}
      pagination={{
        pageSize: tableParams.pagination.pageSize,
        total: tableParams.pagination?.total,
      }}
      onChange={handleTableChange}
      title={() => (
        <>
          {" "}
          <Search
            placeholder="input search text"
            onSearch={handleOnSearch}
            enterButton
          />
        </>
      )}
    />
  );
};
