export default {
  "token": {
    "colorPrimary": "#f9b981",
    "colorPrimaryHover": "#FAC79A",
    "colorPrimaryActive": "#C79467",
    "borderRadius": 2,
    "fontSize": 18,
    "fontSizeHeading1": 43.938,
    "fontSizeHeading2": 35.154,
    "fontSizeHeading3": 28.134,
    "lineHeight": 0.972
  },
  "components": {
    "Anchor": {
      "colorText": "#A381B7",
      "fontSize": 18
    },
    "Dropdown": {
      "colorText": "#A381B7",
      "fontSize": 18
    },
    "Button": {
      "colorText": "#92616F",
      "colorBgContainer": "#ffffff",
      "colorTextLightSolid": "#312025",
      "colorBorder": "#92616F",
      "paddingContentHorizontal": 24,
      "paddingXS": 8
    },
    "Alert": {
      "colorInfo": "#652D87",
      "colorInfoBg": "#E0D5E7",
      "colorInfoBorder": "#652D87",
      "colorError": "#D32F2F",
      "colorErrorBg": "#FFEBEE",
      "colorErrorBorder": "#D32F2F"
    },
    "Checkbox": {
      "colorBorder": "#F5B5C7",
      "controlInteractiveSize": 24,
      "colorWhite": "#000"
    },
    "Input": {
      "colorBorder": "#F5B5C7",
      "colorText": "#92616F"
    },
    "Typography": {
      "colorText": "#281236",
      "colorTextHeading": "#652D87"
    }
  }
}