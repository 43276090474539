import AuthnService from "../../services/auth";
import { StorageService } from "../../services/storage";
import actions from "../actions/actions";
import { put, call, takeLatest } from "redux-saga/effects";

const authService = new AuthnService();
const storageService = new StorageService();

const doAuth = async (payload) => {
  try {
    return await authService.auth(payload);
  } catch (error) {
    throw error;
  }
};

function* dispatchDoAuth(action) {
  try {
    const response = yield call(doAuth, action?.payload);
    if (response.data.success) {            
      const token = "logged-in-user";
      storageService.setItem(StorageService.STORAGE_KEYS.CUSTOMER_TOKEN, token);

      yield put({
        type: actions.ASYNC_AUTH_SUCCESS,
        data: response.data.data,
        msg: response.data.msg,
      });
      yield put(actions.notifySuccess("Logged in!"));
    } else {
      yield put({
        type: actions.ASYNC_AUTH_FAILED,
        msg: response.data.msg,
      });
      yield put(actions.notifyWarning("Login failed!"));
    }
  } catch (error) {
    console.log(error);
    yield put({
      type: actions.ASYNC_AUTH_FAILED,
      msg: error.response?.data?.msg,
    });
    yield put(actions.notifyError("Login failed!"));
  }
}

function* dispatchGetToken(action) {
  try {
    const accessToken = yield call(authService.getToken, action.payload);
    const token = "logged-in-user";

    if (token && token === accessToken) {
      yield put({
        type: actions.ASYNC_GET_TOKEN_SUCCESS,
      });
    } else {
      yield put({
        type: actions.ASYNC_GET_TOKEN_FAILED,
      });
    }
  } catch (error) {
    yield put({
      type: actions.ASYNC_GET_TOKEN_FAILED,
    });
  }
}

export function* listenDoAuth() {
  yield takeLatest(actions.ASYNC_AUTH, dispatchDoAuth);
}

export function* listenGetToken() {
  yield takeLatest(actions.ASYNC_GET_TOKEN, dispatchGetToken);
}