import instance from "./instance";

const getSortOrder = (sortOrder) => {
  if (!sortOrder) return "DESC";

  return sortOrder.includes("descend") ? "DESC" : "ASC";
};

class ActivityService {
  getDebtroActivity = async (payload) => {
    try {
      console.log({ payload });
      return await instance.get(`/activity/debtor`, {
        params: {
          page: payload?.pagination?.current,
          sortBy: payload?.sortField,
          sort: getSortOrder(payload?.sortOrder),
          search: payload?.searchKey,
        },
      });
    } catch (error) {
      console.log("[getDebtroActivity error]", error);
      throw error;
    }
  };
}

export default ActivityService;
