import { Breadcrumb, Layout, Menu, Spin } from "antd";
import React from "react";
import NavBar from "./NavBar";
import MobileSideBar from "./MobileSideBar";
import { isMobile, isTablet } from "react-device-detect";
import { useSelector } from "react-redux";
import { IdleTimer } from "../../common/IdleTimer";
import { Timer } from "../../common/Timer";
import { Header } from "antd/es/layout/layout";
import Sider from "antd/es/layout/Sider";
import { PoundCircleOutlined } from "@ant-design/icons";
import RouteConfig from "../../routes/config";

const { Content } = Layout;

const BaseLayout = ({ children }) => {
  const isLoading = useSelector((state) => state?.loader?.show);

  const items2 = Object.keys(RouteConfig.PRIVATE).map((routeKey, index) => {
    const key = String(index + 1);
    const route = RouteConfig.PRIVATE[routeKey];
    console.log(route);
    return {
      key: `sub${key}`,
      icon: route.ICON,
      label: <a href={route.URL}>{route.TITLE}</a>,
    };
  });

  return (
    <>
      <IdleTimer />

      {isLoading ? <Spin fullscreen /> : ""}

      <Timer />
      <Layout>
        <NavBar />
        <Layout>
          <Sider
            width={200}
            style={{
              height: "100vh",
            }}
          >
            <Menu
              mode="inline"
              defaultSelectedKeys={["1"]}
              defaultOpenKeys={["sub1"]}
              style={{
                height: "100%",
                borderRight: 0,
              }}
              items={items2}
            />
          </Sider>
          <Layout
            style={{
              padding: "0 24px 24px",
            }}
          >
            <Content>{children}</Content>
          </Layout>
        </Layout>
      </Layout>

      {/* <Layout className={isLoading ? "d-none" : ""}>
        <NavBar />
        {isMobile && !isTablet ? <MobileSideBar /> : ""}
        
      </Layout> */}
    </>
  );
};

export default BaseLayout;
