export class StorageService {
  setItem = (key, value) => {
    sessionStorage.setItem(key, value);
  };

  removeItem = (key) => {
    sessionStorage.removeItem(key);
  };

  getItem = (key) => {
    return sessionStorage.getItem(key);
  };

  static STORAGE_KEYS = {
    CUSTOMER_TOKEN: "customer-token",
    TIMER: "logout-timer",
    TIMER_SHOW: "timer-show",
    PRIMARY_CASE: "primary-case",
  };
}
