export const ASYNC_GET_DEBTOR_ACTIVITY = "ASYNC_GET_DEBTOR_ACTIVITY";
export const ASYNC_GET_DEBTOR_ACTIVITY_SUCCESS =
  "ASYNC_GET_DEBTOR_ACTIVITY_SUCCESS";
export const ASYNC_GET_DEBTOR_ACTIVITY_FAILED =
  "ASYNC_GET_DEBTOR_ACTIVITY_FAILED";

export const getDebtroActivity = (payload) => {
  return {
    type: ASYNC_GET_DEBTOR_ACTIVITY,
    payload,
  };
};
